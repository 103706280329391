<template>
  <div class="container">
    <div class="header">
      <div class="header-box">
        <img class="fl" src="../../assets/header-bj.png" alt="">
        <div class="header-menu fl" style="margin-left:25px;">
          <a href="#pattern" class="pattern-a">考证班详情</a>
          <span></span>
          <a href="#adv" class="adv-a">课程优势</a>
          <span></span>
          <a href="#core" class="core-a">课程体验</a>
          <span></span>
          <a href="#course" class="course-a">网校名师</a>
          <span></span>
          <a href="#help" class="help-a">专业助教</a>
          <span></span>
          <a href="#mouth" class="mouth-a">学员口碑</a>
        </div>
      </div>
    </div>
    <div class="banner">
      <Carousel v-model="value1" arrow="never" dots="none" :height="554" autoplay loop>
        <CarouselItem>
          <div class="demo-carousel">
            <img src="../../assets/banner1.jpg" alt="">
          </div>
        </CarouselItem>
        <CarouselItem>
          <div class="demo-carousel">
            <img src="../../assets/banner2.png" alt="">
          </div>
        </CarouselItem>
        <CarouselItem>
          <div class="demo-carousel">
            <img src="../../assets/banner1.jpg" alt="">
          </div>
        </CarouselItem>
        <CarouselItem>
          <div class="demo-carousel">
            <img src="../../assets/banner2.png" alt="">
          </div>
        </CarouselItem>
      </Carousel>
    </div>
    <!-- 公告 -->
    <div class="release">
      <img src="../../assets/tips.png" alt="">
      <p>官方信息公告如下：</p>
      <ul class="release-ul">
        <li>
          <img src="../../assets/card.png" alt="">
          <span class="aa">我能不能报考</span>
        </li>
        <li>
          <img src="../../assets/card.png" alt="">
          <span class="aa">我能不能报考</span>
        </li>
        <li>
          <img src="../../assets/card.png" alt="">
          <span class="aa">我能不能报考</span>
        </li>
        <li>
          <img src="../../assets/card.png" alt="">
          <span class="aa">我能不能报考</span>
        </li>
      </ul>
      <ul class="release-ul">
        <li>
          <img src="../../assets/card.png" alt="">
          <span class="aa">我能不能报考</span>
        </li>
        <li>
          <img src="../../assets/card.png" alt="">
          <span class="aa">我能不能报考</span>
        </li>
        <li>
          <img src="../../assets/card.png" alt="">
          <span class="aa">我能不能报考</span>
        </li>
        <li>
          <img src="../../assets/card.png" alt="">
          <span class="aa">我能不能报考</span>
        </li>
      </ul>
    </div>
    <div class="adopt">
      <div class="adopt-box">
        <a class="ml50">了解应考策略>></a>
        <a class="ml230">咨询必考点>></a>
        <a class="ml220">下载最新考试大纲>></a>
      </div>
    </div>
    <div class="road">
      <h1 class="tit">为什么基金考试官方通过率只有30%</h1>
      <div class="des">取证路上困难多</div>
      <a href="">
        <img src="../../assets/examination.png" alt="">
      </a>
      <a class="road-btn">申请制定学习计划</a>
    </div>
    <div class="pattern" id="pattern">
      <div class="abtns"></div>
      <a class="abtn">基金考证班咨询</a>
    </div>
    <div class="adv" id="adv">
      <a>
        <img class="img" src="../../assets/fund.png" alt="">
      </a>
      <a class="abtn">咨询保障详情</a>
    </div>
    <div class="appdw">
      <div class="appdw-box"></div>
      <a class="abtn appdw-btn">免费体验课程</a>
    </div>
    <div class="core" id="core">
      <div class="banner swiper-content swiper-no-swiping" @mouseenter="on_top_enter" @mouseleave="on_top_leave">
        <swiper ref="mySwiper" :options="swiperOption" class="show-swiper">
          <swiper-slide>
            <div class="swiper-item">
              <!-- <span>{{ item }}</span> -->
              <img src="../../assets/swiperSlide1.jpg" alt="">
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="swiper-item">
              <img src="../../assets/swiperSlide2.png" alt="">
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="swiper-item">
              <img src="../../assets/swiperSlide1.jpg" alt="">
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="swiper-item">
              <img src="../../assets/swiperSlide2.png" alt="">
            </div>
          </swiper-slide>
          <!-- <div class="controls"> -->
            <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div>
          <!-- </div> -->
        </swiper>
      </div>
      <a class="abtn core-btn">免费体验课程</a>
    </div>
    <div class="course" id="course">
      <div class="course-box">
        <ul>
          <li>
            <img src="../../assets/laoshi1.png" alt="">
            <h1>马文雅</h1>
            <p class="des">
              <span>马文雅 : </span>
              华金金考专职讲师、研究员，从事金融培训行业8年，具备丰富的教学研究经验。多年从事AFP、基金从业、银行从业、证券从业等金融职业考试课程的研发和授课，讲课风格条理清晰，广受学员们的欢迎。累计培训学员超过10000名。
            </p>
          </li>
          <li>
            <img src="../../assets/laoshi1.png" alt="">
            <h1>赵静远</h1>
            <p class="des">
              <span>赵静远 : </span>
              毕业于对外经济贸易大学国际金融专业，AFP持证人。从事金融分析及培训相关行业近7年，累积培训金融从业者近5万人。
            </p>
          </li>
          <li>
            <img src="../../assets/laoshi1.png" alt="">
            <h1>李翠美</h1>
            <p class="des">
              <span>李翠美 : </span>
              现任华金金考研究员和专职讲师，专业从事AFP、银行从业、期货从业、中级经济师等考试课程的研究和授课，讲解风格通俗易懂、亲切自然。
            </p>
          </li>
          <li>
            <img src="../../assets/laoshi1.png" alt="">
            <h1>朱琳</h1>
            <p class="des">
              <span>朱琳 : </span>
              现任华金金考研究员和专职讲师，专业从事AFP、证券从业、银行从业、中级经济师等考试课程的研究和授课，严谨而不失风趣，层层剖析、环环相扣。
            </p>
          </li>
        </ul>
      </div>
      <div class="course-abtn">
        <a href="" v-for="i in 4" :key="i">咨询名师详情</a>
      </div>
    </div>
    <div class="help" id="help">
      <a class="abtn">了解助教服务</a>
    </div>
    <div class="rate">
      <a class="abtn">咨询基金考证班</a>
    </div>
    <div class="mouth" id="mouth">
      <img class="img-top" src="../../assets/koubei.png" alt="">
      <div class="mouth-box">
        <ul>
          <li>
            <img src="../../assets/liaotian1.png" alt="">
          </li>
          <li>
            <img src="../../assets/liaotian1.png" alt="">
          </li>
          <li>
            <img src="../../assets/liaotian1.png" alt="">
          </li>
          <li>
            <img src="../../assets/liaotian1.png" alt="">
          </li>
          <li>
            <img src="../../assets/liaotian1.png" alt="">
          </li>
          <li>
            <img src="../../assets/liaotian1.png" alt="">
          </li>
        </ul>
      </div>
      <a class="abtn">咨询基金考证班</a>
    </div>
    <div class="footer"></div>
    <div class="footer_bottom">
      <p>
        <a href="">关于我们</a>
        <a href="">联系我们</a>
      </p>
      <p>Copyright © 2010-2020 华金金考教育科技有限公司 版权所有 京ICP备18012967号-1 </p>
      <p>咨询电话：400-086-1818</p>
    </div>
    <div class="left-ly">
      <ul>
        <li>基金考证班</li>
        <li>团报客户咨询</li>
        <li>上班族如何备考</li>
        <li>零基础如何学习</li>
        <li>屡考不过怎么办</li>
        <li>时间不够怎么学</li>
      </ul>
    </div>
    <div class="give"></div>
    <div class="css3btn">
      <a>
        <img src="../../assets/avatar.png" alt="">
        <p>有位老师想和您聊一聊</p>
      </a>
    </div>
    <form class="callbox">
      <input type="text" id="tel" value="输入电话申请试听课程!">
      <input type="submit" class="callBoxbtn">
    </form>
    <div id="tip" ref="tip">
      <img src="../../assets/jj1.png" alt="">
      <div class="al_close" @click="close"></div>
    </div>
  </div>
</template>

<script>
import 'swiper/swiper-bundle.css'
import {
  swiper,
  swiperSlide
} from 'vue-awesome-swiper'
export default {
  components: {
    swiper,
    swiperSlide
  },
  data () {
    return {
      value1: 1,
      list: [1, 2, 3, 4, 5, 6],
      swiperOption: {
        slidesPerView: 'auto',
        loop: true,
        watchSlidesProgress: true,
        centeredSlides: true,
        // 设置自动轮播
        autoplay: {
          delay: 2000,
          disableOnInteraction: false
        },
        navigation: {
          prevEl: '.swiper-button-prev',
          nextEl: '.swiper-button-next'
        },
        on: {
          progress: function () {
            for (let i = 0; i < this.slides.length; i++) {
              const slide = this.slides.eq(i) // 指定匹配元素集缩减值
              const slideProgress = this.slides[i].progress // 当前元素集的progress值

              let modify = 0 // 偏移权重
              if (parseInt(Math.abs(slideProgress)) > 0) {
                modify = Math.abs(slideProgress) * 0.2 // 不一定要0.2，可自行调整
              }
              const translate = slideProgress * modify * 500 + 'px' // 500是swiper-slide的宽度
              const scale = 1 - Math.abs(slideProgress) / 5 // 缩放权重值，随着progress由中向两边依次递减，可自行调整
              const zIndex = 99 - Math.abs(Math.round(10 * slideProgress))
              slide.transform(`translateX(${translate}) scale(${scale})`)
              slide.css('zIndex', zIndex)
              slide.css('opacity', 1) // 是否可见
              if (parseInt(Math.abs(slideProgress)) > 1) { // 设置了只有选中的元素以及他两遍的显示，其他隐藏
                slide.css('opacity', 0)
              }
            }
          }
        }
      }
    }
  },
  computed: {
    swiper () {
      return this.$refs.mySwiper.swiper
    }
  },
  mounted () {
    this.initSwiper()
  },
  methods: {
    initSwiper () {
      this.$nextTick(async () => {
        // await this.swiper.init() // 现在才初始化
      })
    },
    on_top_enter () {
      this.swiper.autoplay.stop()
    },
    on_top_leave () {
      this.swiper.autoplay.start()
    },
    close () {
      this.$refs.tip.style.display = 'none'
    }
  }
}
</script>

<style lang="less" scoped>
.ml50 {
  margin-left: 50px;
}
.ml230 {
  margin-left: 230px;
}
.ml220 {
  margin-left: 220px;
}
.mt7 {
  margin-top: 14px;
}
.img {
  width: 100%;
}
.abtn {
  padding: 15px 30px;
  background: #e43f36;
  background-image: linear-gradient(120deg, #ec4f3d, #e61f1b);
  font-size: 28px;
  color: #fff;
  text-decoration: none;
  box-shadow: 2px 2px 7px #9E948E;
  border-radius: 45px;
  position: absolute;
  left: 50%;
  cursor: pointer;
}
a {cursor: pointer;}
.container {
  width: 100%;
  margin: 0 auto;
  background: #ffffff;
  overflow-x: hidden;
  .header {
    position: fixed;
    top: 0;
    z-index: 9999;
    width: 100%;
    height: 69px;
    background: #1e2634;
    .fl {
      float: left;
    }
    .header-box {
      width: 1220px;
      height: 69px;
      margin: 0 auto;
      cursor: pointer;
      .header-menu > span {
        width: 2px;
        height: 20px;
        background: #716e6e;
        float: left;
        margin-top: 25px;
      }
      .header-menu > a {
        float: left;
        line-height: 69px;
        color: #fff;
        font-size: 18px;
        padding: 0 33px;
        text-decoration: none;
        &:hover {
          color: red;
          text-decoration: underline;
        }
      }
    }
  }
  .banner {
    width: 100%;
    height: 544px;
    overflow: hidden;
    position: relative;
    z-index: 9;
    margin-top: 69px;
    .demo-carousel {
      width: 100%;
      height: 100%;
    }
    img {
      width: 100%;
      height: 100%;
      display: block;
    }
  }
  .release {
    padding-bottom: 80px;
    padding-top: 80px;
    p {
      font-size: 30px;
      color: #f12e30;
      text-align: center;
      padding-bottom: 35px;
    }
    .release-ul {
      width: 1300px;
      margin: 0 auto;
      overflow: hidden;
      padding: 5px 0;
      list-style: none;
      li {
        width: 265px;
        height: 323px;
        float: left;
        margin: 40px 15px 0 35px;
        border: 1px solid #e8e8e8;
        border-radius: 10px;
        box-shadow: 0 0px 15px #bfbcbc;
        cursor: pointer;
        position: relative;
        transition: transform 0.3s;
        &:hover {
          transform: translateY(-15px);
          span {
            background-color: #2399f9;
            background-image: linear-gradient(120deg, #2d9dff, #018de7);
          }
        }
        span {
          width: 150px;
          height: 40px;
          position: absolute;
          bottom: 30px;
          border-radius: 10px;
          text-align: center;
          line-height: 40px;
          font-size: 16px;
          color: #fff;
          background: #e64237;
          background-image: linear-gradient(120deg, #ec4f39, #dd3130);
          left: 50%;
          margin-left: -75px;
          transition: all 0.5s ease-out;
          box-shadow: 2px 2px 7px #9e948e;
        }
      }
    }
  }
  .adopt {
    width: 100%;
    height: 818px;
    background: url(../../assets/zhengshu.png) no-repeat top center;
    .adopt-box {
      width: 1108px;
      height: 55px;
      margin: 0 auto;
      padding-top: 630px;
      a {
        padding: 10px 20px;
        background: #dd3130;
        background-image: linear-gradient(120deg, #ec4f39, #dd3130);
        font-size: 20px;
        color: #fff;
        border-radius: 25px;
        position: relative;
        transition: transform 0.3s;
        display: inline-block;
        &:hover {
          transform: translateY(-15px);
        }
      }
    }
  }
  .road {
    margin-bottom: 100px;
    .tit {
      color: #333;
      font-size: 40px;
      text-align: center;
      padding: 65px 0 30px 0;
      position: relative;
    }
    .des {
      color: #333;
      padding-bottom: 10px;
    }
    img {
      margin: 0 auto;
      display: block;
      margin-bottom: 50px;
    }
    .road-btn {
      left: 0;
      font-size: 40px;
      padding: 15px 30px;
      border-radius: 40px;
      background: #dd3130;
      background-image: linear-gradient(120deg, #ec4f39, #dd3130);
      color: #fff;
      line-height: 48px;
      position: relative;
      box-shadow: 2px 2px 7px #9E948E;
      display: inline-block;
      transition: transform 0.3s;
      &:hover {
        transform: translateY(-15px);
      }
    }
  }
  .pattern {
    width: 100%;
    height: 1000px;
    background: url(../../assets/livebroadcast.png) no-repeat center;
    position: relative;
    .abtn {
      top: 805px;
      margin-left: -128px;
      transition: transform 0.3s;
      &:hover {
        transform: translateY(-15px);
      }
    }
  }
  .adv {
    width: 100%;
    height: 980px;
    position: relative;
    .abtn {
      margin-left: -114px;
      top: 850px;
      line-height: 34px;
    }
  }
  .appdw {
    width: 100%;
    height: 1127px;
    background: url(../../assets/bj.png) no-repeat center top;
    position: relative;
    .appdw-box {
      width: 1158px;
      height: 930px;
      margin: 0 auto;
      background: url(../../assets/app.png) no-repeat center;
      position: relative;
    }
    .appdw-btn {
      box-shadow: none;
      top: 985px;
      margin-left: -114px;
    }
  }
  .core {
    width: 100%;
    height: 1030px;
    background: url(../../assets/zhongxin.png) no-repeat center 60px;
    position: relative;
    .banner {
      width: 1025px;
      height: 710px;
      margin: 0 auto;
      padding-top: 235px;
      .swiper-slide {
        width: 500px;
        // 表示所有属性都有动作效果，过度时间为0.4s，以慢速开始和结束的过渡效果
        transition: all .4s cubic-bezier(.4, 0, .2, 1);
      }
      .swiper-item {
        width: 100%;
        height: 500px;
        border-radius: 6px;
        color: orangered;
        font-size: 24px;
        line-height: 1.5;
        img {
          box-shadow: 0 0 35px #9E948E;
        }
      }
      .swiper-content {
        width: 100%;
        height: 100%;
        position: relative;
        overflow: hidden;
        margin: 0 auto;
        padding: 50px 0;
        .show-swiper {
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
        }
      }
      .controls {
        position: relative;
        z-index: 99;
        top: -388px;
      }
      .swiper-button-prev,.swiper-button-next {
        width: 80px;
        height: 80px;
        outline: none;
      }
    }
    .core-btn {
      margin: 50px 0 0 -114px;
      top: 855px;
    }
  }
  .course {
    width: 100%;
    height: 780px;
    background: url(../../assets/mingshi.png) no-repeat center;
    position: relative;
    .course-box {
      width: 1230px;
      height: 385px;
      margin: 0 auto;
      padding-top: 220px;
      li {
        width: 285px;
        height: 382px;
        float: left;
        margin: 0 11px;
        border-radius: 8px;
        overflow: hidden;
        position: relative;
        &:hover h1 {
          transition: opacity 300ms linear;
          opacity: 0;
        }
        &:hover .des {
          transition: opacity 300ms linear;
          display: block;
          opacity: 1;
        }
        h1 {
          width: 100%;
          position: absolute;
          color: #fff;
          bottom: 0;
          text-align: center;
          height: 50px;
          line-height: 50px;
          background-color: rgba(0,0,0,0.64);
        }
        .des {
          position: absolute;
          bottom: 0;
          padding: 10px;
          line-height: 28px;
          color: #fff;
          background-color: rgba(0,0,0,0.64);
          font-size: 14px;
          display: none;
          span {
            font-size: 18px;
          }
        }
      }
    }
    .course-abtn {
      width: 1230px;
      height: 55px;
      position: absolute;
      bottom: 0;
      left: 50%;
      margin-left: -615px;
      bottom: 55px;
      text-align: center;
      a {
        padding: 10px 30px;
        background: #e43f36;
        background-image: linear-gradient(120deg, #ec4f3d, #e61f1b);
        color: #fff;
        text-decoration: none;
        box-shadow: 2px 2px 7px #272422;
        border-radius: 45px;
        margin: 0 61px;
        font-size: 20px;
        &:hover {
          background: #018de7;
          background-image: linear-gradient(120deg, #2e9dff, #018de7);
        }
      }
    }
  }
  .help {
    width: 100%;
    height: 960px;
    background: url(../../assets/dayi.png) no-repeat center top;
    position: relative;
    a{
      margin: 0;
      margin-left: -119px;
      top: 805px;
      transition: transform 0.3s;
      &:hover {
        transform: translateY(-15px);
      }
    }
  }
  .rate {
    width: 100%;
    height: 861px;
    background: url(../../assets/tongguo.png) no-repeat center;
    position: relative;
    a {
      margin: 0;
      margin-left: -128px;
      top: 750px;
      transition: transform 0.3s;
      &:hover {
        transform: translateY(-15px);
      }
    }
  }
  .mouth {
    margin-bottom: 200px;
    position: relative;
    .img-top {
      display: block;
      margin: 35px auto 30px;
    }
    .abtn {
      top: 720px;
      margin-left: -128px;
    }
    .mouth-box {
      width: 1200px;
      height: 476px;
      overflow: hidden;
      margin: 0 auto;
      position: relative;
      ul {
        height: 476px;
        display: flex;
        li {
          float: left;
          margin-right: 42px;
          list-style: none;
        }
      }
    }
  }
  .footer {
    width: 100%;
    height: 400px;
    background: url(../../assets/footer.png) no-repeat center;
  }
  .footer_bottom {
    height: 150px;
    font-size: 14px;
    text-align: center;
    color: #7e7e7e;
    line-height: 20px;
    margin-top: 40px;
    p {
      margin: 14px 0;
    }
    a {
      color: #7e7e7e;
      padding: 0 10px;
      text-decoration: underline;
    }
  }
  .left-ly {
    width: 116px;
    height: 560px;
    position: fixed;
    z-index: 999;
    left: 30px;
    margin-top: -280px;
    background: url(../../assets/leftsilde.png) no-repeat;
    top: 50%;
    ul {
      margin-top: 159px;
      li {
        font-size: 14px;
        padding-top: 16px;
        text-align: center;
        color: #fff;
        cursor: pointer;
      }
    }
  }
  .give {
    width: 175px;
    height: 299px;
    background: url(../../assets/book.png) no-repeat center;
    position: fixed;
    top: 25%;
    right: 0;
    cursor: pointer;
    z-index: 299;
  }
  .css3btn {
    bottom: 400px;
    right: 5px;
    z-index: 9999999999;
    position: fixed;
    margin-top: -30px;
    a {
      display: block;
      text-decoration: none;
    }
    img {
      left: -10px;
      top: 50%;
      margin-top: -35px;
      position: absolute;
      z-index: 9999;
      border: 0;
    }
    p {
      font-size: 15px;
      width: 230px;
      height: 45px;
      line-height: 45px;
      text-indent: 55px;
      text-align: center;
      color: #fff;
      display: block;
      overflow: hidden;
      position: relative;
      background: #ff5400;
      background: linear-gradient(rgba(254,84,0,.8),rgba(255,118,50,.8));
      text-transform: uppercase;
      border-radius: 20px;
    }
  }
  .callbox {
    width: 150px;
    height: 150px;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 9999;
    background: url(../../assets/form.png);
    #tel {
      border: 0 none;
      outline: 0 none;
      background: #fff;
      width: 90%;
      display: block;
      margin: 90px auto 5px;
      color: #333;
      font-size: 12px;
      padding: 5px 3px;
      border-radius: 5px;
      font-weight: bold;
      text-align: center;
    }
    .callBoxbtn {
      border: 0 none;
      outline: 0 none;
      color: #fff;
      background: #329fff;
      font-size: 13px;
      padding: 3px 10px;
      border-radius: 15px;
      margin: 0 auto;
      display: block;
      cursor: pointer;
      font-weight: bold;
    }
  }
  #tip {
    width: 513px;
    height: 354px;
    position: fixed;
    top: 50%;
    left: 50%;
    margin: -191px 0 0 -310px;
    z-index: 999999;
    .al_close {
      width: 40px;
      height: 40px;
      color: red;
      font-size: 22px;
      position: absolute;
      top: 1%;
      right: 1%;
      cursor: pointer;
    }
  }
}
</style>
